import { ConfigurationBackdrop } from "@plansoft-configuration-renderer/components";
import {
  ConfigurationController,
  ConfigurationInitializer,
  ConfigurationRenderer,
  HeartbeatController,
} from "@plansoft-configuration-renderer/framework";
import { SettingsSync } from "@plansoft-configuration-renderer/layout";

import { ConfigurationStateSpy } from "./ConfigurationStateSpy";
import { CustomDebugTools } from "./CustomDebugTools";
import { CustomRenderer } from "./CustomRenderer";

export interface MinimalRendererProps {
  /**
   * Configuration object id
   */
  cfgObjectId: string;
  /**
   * Configuration language / locale code
   */
  language: string;
  /**
   * The base URL must be mapped by the proxy
   */
  baseUrl: string;
  /**
   * Use a specific layout, only works for the root cfg object
   */
  layoutId?: string;
}

/**
 * This is the base setup for the renderer
 *
 * - the `CsCfgObjectController` starts the configuration
 * - the `ConfigurationController` provides the necessary state management
 */
export function MinimalRenderer(props: MinimalRendererProps): JSX.Element {
  // see setupProxy.js in src folder
  // in production this could be loaded from the database et
  const { baseUrl, ...startOptions } = props;

  // if any start option changes, reset the ConfigurationController
  const configurationResetKey = Object.values(props).join("#");

  return (
    <ConfigurationController baseUrl={baseUrl} key={configurationResetKey}>
      {/* ConfigurationStarter starts to configuration but renders nothing. Also see ConfigurationResumer */}
      <ConfigurationInitializer
        cfgObjectId={startOptions.cfgObjectId}
        localeId={startOptions.language}
      />

      {/* ConfigurationRenderer prevents the configuration from rendering when there is not cfg object yet */}
      <ConfigurationRenderer>
        <CustomDebugTools />
        {/* Renders nothing, but keeps the session alive */}
        <HeartbeatController interval={60e3} />
        {/* Sync settings to redux store, omit if not needed */}
        <SettingsSync />
        {/* Display loading spinner when requests are open */}
        <ConfigurationBackdrop />
        {/* Renders the configuration */}
        <CustomRenderer />
        {/* Spy on configuration state changes */}
        <ConfigurationStateSpy />
      </ConfigurationRenderer>
    </ConfigurationController>
  );
}
