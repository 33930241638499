import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { PaletteOptions } from "@mui/material/styles/createPalette";

export const customPalette = {
  mode: "light",
} satisfies PaletteOptions;

const customTheme = createTheme({
  palette: customPalette,
  colorSchemes: {
    dark: false,
  },
  typography: {},
  shape: {},
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});

export const theme = responsiveFontSizes(customTheme);
