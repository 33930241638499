import { theme } from "../theme/theme";
import { RecoilRoot } from "recoil";

import { ReactNode, Suspense } from "react";

import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";
import LinearProgress from "@mui/material/LinearProgress";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { mergeConfigurationTheme } from "@plansoft-configuration-renderer/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const customTheme = createTheme(mergeConfigurationTheme(theme));

interface MinimalAppContextProps {
  children: ReactNode;
}

const queryClient = new QueryClient();

//removes the vendor prefix on dev
const cacheNoPrefixer = createCache({
  key: "noprefixer",
  ...(process.env.NODE_ENV === "development" && { stylisPlugins: [] }),
});

export function MinimalAppContext(props: MinimalAppContextProps): JSX.Element {
  return (
    <CacheProvider value={cacheNoPrefixer}>
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools buttonPosition="bottom-left" />
          <ThemeProvider theme={customTheme}>
            <CssBaseline />
            <Suspense fallback={<LinearProgress />}>{props.children}</Suspense>
          </ThemeProvider>
        </QueryClientProvider>
      </RecoilRoot>
    </CacheProvider>
  );
}
