import { Suspense } from "react";

import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  CommonErrorBoundary,
  DateLocalizationProvider,
  LocalizationProvider,
  OAuthManager,
} from "@plansoft-configuration-renderer/components";
import {
  GatewayDest,
  GatewayProvider,
} from "@plansoft-configuration-renderer/gateway";

import { MinimalAppContext } from "./renderer/MinimalAppContext";
import { MinimalRenderer } from "./renderer/MinimalRenderer";

// see vite.config.ts for the proxy configuration
const CONFIGURATOR_BASE_URL = "/preview";
const LANGUAGE = "en-US";
const CFG_OBJECT = "CFG_SELECTION";

export function App(): JSX.Element {
  return (
    <MinimalAppContext>
      <CommonErrorBoundary>
        <Suspense>
          <LocalizationProvider
            configurationLocale={LANGUAGE}
            uiLocale={LANGUAGE}
            configuratorBasePath={CONFIGURATOR_BASE_URL}
          >
            <DateLocalizationProvider localeCode={LANGUAGE}>
              <GatewayProvider>
                <Grid container spacing={10} position="relative">
                  <Grid
                    size={{
                      xs: 2,
                    }}
                    sx={{ position: "sticky", top: 0, maxHeight: "90vh" }}
                  >
                    <GatewayDest name="navigation" />
                  </Grid>
                  <Grid size={{ sm: 8 }}>
                    <Box>
                      <MinimalRenderer
                        baseUrl={CONFIGURATOR_BASE_URL}
                        cfgObjectId={CFG_OBJECT}
                        language={LANGUAGE}
                        layoutId="LAYOUT_CFG_SELECTION_NEW"
                      />
                      <OAuthManager
                        basePath={CONFIGURATOR_BASE_URL}
                        isLocalDevelopment={import.meta.env.DEV}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </GatewayProvider>
            </DateLocalizationProvider>
          </LocalizationProvider>
        </Suspense>
      </CommonErrorBoundary>
    </MinimalAppContext>
  );
}
